import React, { Component } from "react";
import "./AboutMe.css";
const { $ } = window;

class AboutMe extends Component {
  componentDidMount() {
    $(".collapsible").collapsible();
    $(".scrollspy").scrollSpy("methodName");
  }

  render() {
    return (
      <div className="pushin ">
        <ul className="collapsible popout">
          <li>
            <div className=" collapsible-header">ABOUT ME</div>
            <div className="collapsible-body center-align">
              <div>
                <span>
                  {" "}
                  Greetings! I'm Ohad Katz, a Systems Development Engineer at Amazon. 
                  Explore my resume to discover my professional journey and notable achievements. 
                  Feel free to connect with me on LinkedIn. {" "}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default AboutMe;
